import SLLogo from "assets/img/brand/sl/justicia-logo-san-luis.png";
import SLLogoLight from "assets/img/brand/sl/logo-san-luis-light.png";
import SLFav from "assets/img/brand/sl/favicon-san-luis.png";
import DefaultSL from "assets/img/brand/sl/defaultSan Luis.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'sl';

export const sources = {
    sl: {
        url: 'https://subastas.justiciasanluis.gov.ar',
        logo: SLLogo,
        logolight: SLLogoLight,
        ws_path: 'wss://subastas.justiciasanluis.gov.ar/wss',
        name: 'Subastas Judiciales San Luis',
        navbar_class: 'navbar-sl',
        headroom_class: 'headroom-sl',
        favicon: SLFav,
        defaultImg: DefaultSL,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        theme_color: '#A80019',
        theme_name: 'sanluis',
        title: 'Subastas Judiciales',
        recaptcha_key: '6Lfjl9gdAAAAANRPzMyfQnqqpwJCl-XVjuK9GpU4',
        gtm_ID: 'GTM-KXV5TQ9',
        contact_data: {
            title: 'Mesa de Ayuda de la Secretaría de Informática',
            lines: [
                'Teléfono: (0266) 4379050',
            ]
        },
        support_email: 'soportesl@esubasta.ar'
    },
};